import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import config from "./config";
import service from "./api-service";
import VueUtils from "@/libs/vue-utils";
import Vant from "vant";
import { Icon } from 'vant';


import "vant/lib/index.css";
import { getPlatform } from "@/libs/common-utils/common-utils";
import VConsole from "vconsole";
import './permission'

Vue.prototype.$config = config;
Vue.prototype.$service = service;

Vue.config.productionTip = false;
Vue.use(Vant)
  .use(VueUtils);
if (store.getters.env_mode && store.getters.env_mode == "test") {
  Vue.use(new VConsole());
}
initializtionProgram();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/**
 * 初始化工作 配置渠道入口
 */
function initializtionProgram() {
  let platform = getPlatform();
  store.commit("setPlatformType", platform);
  let env_mode =
    decodeURIComponent(
      (new RegExp("[?|&]" + "env_mode" + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null;
  if (env_mode) store.commit("setEnvMode", env_mode);
}
