<template>
<div id="app">
    <router-view />
    <van-tabbar route v-show="this.$route.meta.showTab" active-color="#8565bd" inactive-color="#000">
        <van-tabbar-item replace to="/pool" icon="wap-home-o">订单池</van-tabbar-item>
        <van-tabbar-item replace to="/wait" icon="orders-o">待出票</van-tabbar-item>
        <van-tabbar-item replace to="/history" icon="balance-list-o">竞价记录</van-tabbar-item>
        <van-tabbar-item replace to="/ticket" icon="home-o">已出票</van-tabbar-item>
        <van-tabbar-item replace to="/rule" icon="list-switching">自动报价</van-tabbar-item>
        <van-tabbar-item replace to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
    <div style="display:none ">
        <audio ref="audio2" :src="require('@/assets/tip.mp3')" :autoplay="false" />
    </div>
    <div style="display:none ">
        <audio ref="audio3" :src="require('@/assets/neworder.ff3a63f4.mp3')" :autoplay="false" />
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import Api from '@/api-service'
export default {
    data() {
        return {
            order_id : 0
        }
    },
    mounted() {
        this.$nextTick(() => {
            setInterval(this.playTipMp2, 1000 * 10)
            setInterval(this.playTipMp3, 1000 * 5)
        })
    },
    computed: {
        ...mapGetters(['hasGetUserInfo']),
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    methods: {
        playTipMp2() {
            if (!this.hasGetUserInfo) {
                console.log('not login')
                return
            }
            Api.hasMyOrder({}).then(res => {
              console.log(res)
                if (res.has_new_order) {
                    this.$refs.audio2.play()
                }
            })
        },
        playTipMp3() {
            if (!this.hasGetUserInfo) {
                console.log('not login')
                return
            }
            Api.hasNewOrder({order_id:this.order_id}).then(res => {
              console.log(res)
                if (res.has_new_order) {
                    this.order_id = res.order_id
                    this.$refs.audio3.play()
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import './styles/index.scss';

html,
body,
#app {
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
    // font-family: "pf regular";
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
}

#app {
    display: flex;
    flex-direction: column;
}
</style>
