import axios from "axios";
import store from "@/store";
import router from "@/router";
import qs from "qs";
import globalConfig from "@/config";
import { Toast } from "vant";
import { goPage } from "@/libs/vue-utils";
import cookies from "vue-cookies";
import config from "@/config";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
axios.defaults.withCredentials = false;

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      timeout: globalConfig.requestTimeout,
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
    };
    return config;
  }
  interceptors(instance, url) {
    //请求拦截器
    instance.interceptors.request.use(
      (config) => {
        //清除加载框
        if (!Object.keys(this.queue).length) {
          //
        }
        this.queue[url] = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    //响应拦截器
    instance.interceptors.response.use(
      (res) => {
        this.destroy(url);
        const { data, status } = res;
        if (status === 200) {
          if (data.code === 200) {
            return data.data;
          } else {
            return handleServerException(data);
          }
        }
      },
      (error) => {
        this.destroy(url);
        return Promise.reject(error);
      }
    );
  }
  /**
   * 执行请求
   * @param {请求config 包含data以及config} options
   * @returns
   */
  request(options) {
    const instance = axios.create();
    options.method = options.method ? options.method : "POST";

    console.log(options)
    options = Object.assign(this.getInsideConfig(), options);
    let apiRouteMethod = options.url;
    let cToken = cookies.get(config.const.KEY_AUTH_CODE);
    // cToken? (options.headers["c-token"] = cToken): "";
    cToken? (options.headers["Authorization"] = cToken): "";
    if(options.headers['Content-Type'] != 'multipart/form-data'){
      options.data = qs.stringify(options.data,{ arrayFormat: 'brackets' });
    }
    console.log(options)
    this.interceptors(instance, apiRouteMethod);
    return instance(options);
  }

  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
}

export default HttpRequest;

function handleServerException(data) {
  if (!data.success) {
    // let error = data.error;
    // if (error) {
    let errorCode = data.code;
    let errorMsg = data.msg;
    //处理相关操作
    switch (errorCode) {
      case 7000:
        //api用户不存在
        Toast.fail(errorMsg);
        // store.commit('setAppKey', null);
        break;
      case -100:
        //未登录
        Toast.fail(errorMsg);
        store.commit("removeAuthCode");
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
        break;
      case -101:
        //登录信息失效
        Toast.fail(errorMsg);
        store.commit("removeAuthCode");
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
        break;
      case 6021:
        //未支付订单
        console.log(errorCode, errorMsg);
        break;
      case "6001":
        //订单不存在
        goPage("error404", null, true);
        break;
      case 6040:
        break;
      default:
        console.log("HttpRequest -> handleServerException Default Bug");
        Toast.fail(errorMsg);
        break;
    }

    //这里默认返回固定错误消息体
    return Promise.reject({
      errorCode,
      errorMsg,
      data: data.data,
    });
    // }
  }
  return Promise.reject();
}
